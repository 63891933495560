<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>商城管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/goods' }">商品列表</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info" v-loading="detailLoading">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini">


                <el-form-item label="名称：" label-position="left" prop="name">
                    <el-input style="width: 200px" v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="图片：" label-position="left" prop="pic">
                    <edit-cover :pic="imageUrl + form.pic" :uploadPath="uploadPath" @success="uploadSuccess">
                    </edit-cover>
                </el-form-item>

                <el-form-item label="详情：" label-position="left" prop="content">
                    <editor @onChange="onChange" :content="form.content"></editor>
                </el-form-item>

                <el-form-item label="类型：" label-position="left" class="input">
                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" @change="selectType">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="支付方式：" label-position="left" class="input">
                    <el-select style="width: 200px" v-model="form.payType" placeholder="请选择">
                        <el-option v-for="item in payment" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="价格：" v-if="form.payType == 1||form.payType == 2" label-position="left" class="input" prop="price">
                    <el-input style="width: 200px" v-model.trim="form.price"></el-input>（元/余额）
                </el-form-item>

                <el-form-item label="学币：" v-if="form.payType == 2||form.payType == 3" label-position="left" class="input" prop="credit">
                    <el-input style="width: 200px" v-model.trim="form.credit"></el-input>（学币）
                </el-form-item>


                <el-form-item label="全额支付：" v-if="form.payType == 2" label-position="left" class="input">
                    <el-radio v-model="form.pricePay" :label="1">确定</el-radio>
                    <el-radio v-model="form.pricePay" :label="0">取消</el-radio>
                </el-form-item>


                <el-form-item label="金额：" v-if="form.pricePay == 1 && form.payType == 2" label-position="input"
                    class="input" prop="convertPrice">
                    <el-input style="width: 200px" v-model="form.convertPrice"></el-input>（学币不够可余额支付）
                </el-form-item>


                <el-form-item label="最大购买数量：" label-position="left" class="input">
                    <el-input style="width: 200px" v-model="form.maxCount"></el-input>（0为无限制）
                </el-form-item>

                <el-form-item label="是否需要地址：" label-position="left" class="input">
                    <el-switch v-model="form.isAddress" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{ form.isAddress === 1 ? '是' : '否' }}</span>
                </el-form-item>


                <el-form-item label="状态：" label-position="left" class="input">
                    <el-switch v-model="form.status" @change="setStatus" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{ statusTxt }}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/goods" class="router-link">
                        <el-button style="margin-left: 10px" size="small">取消</el-button>
                    </router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import Editor from "../../../components/editor";
import EditCover from "../../../components/editCover";

export default {
    name: "template-add",
    components: { EditCover, Editor },
    data() {
        return {
            detailLoading: false,
            imageUrl: config.imageUrl,
            statusTxt: '上架',
            loading: false,
            uploadPath: 'store',
            form: {
                id: '',
                name: '',
                type: 0,
                status: 1,
                pic: '',
                price: 0,
                content: '',
                maxCount: 0,
                isAddress: 1,
                payType: 1,
                convertPrice: 0,
                pricePay: 1,
                credit: 0,
            },
            options: [
                {
                    value: 1,
                    label: '实物商品'
                },
                {
                    value: 2,
                    label: '积分商品'
                },
            ],
            payment: [
              {
                value: 1,
                label: '余额支付'
              },
              {
                value: 3,
                label: '积分支付'
              },
              {
                value: 2,
                label: '组合支付'
              },

            ],
            rules: {
                name: [
                    { required: true, message: '请输入课程名称', trigger: 'blur' },
                ],
                pic: [
                    { type: 'string', required: true, message: '请选择图片封面', trigger: 'change' }
                ],
                content: [
                    { type: 'string', required: true, message: '请输入课程内容', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '请输入价格/积分', trigger: 'blur' },
                ],
                credit: [
                    { required: true, message: '请输入积分', trigger: 'blur' },
                ],
                convertPrice: [
                    { required: true, message: '请输入价格', trigger: 'blur' },
                ],
            },

        }
    },
    methods: {
        ...mapActions('store', ['addGoods', 'getStoreDetail']),
        uploadSuccess(path) {
            this.form.pic = path
        },
        onChange(content) {
            this.form.content = content
        },
        async onSubmit() {
            let _this = this
            this.loading = true
            console.log(this.form)

            if (!this.validateForm('form')) {
                this.$message.error('请填写必填选项！')
                return false
            }
            this.form.pricePay = this.form.payType == 0 ? 0 : this.form.pricePay
            await this.addGoods(this.form).then(res => {
                if (res.ret == 0) {
                    _this.$message.success('新增成功！')
                    _this.$router.push('/goods')
                }
            })
            this.loading = false
        },
        setStatus(status) {
            console.log(status)
            if (this.form.status) {
                this.statusTxt = '上架'
            } else {
                this.statusTxt = '下架'
            }
            this.form.status = status
        },
        selectType(val) {
            this.form.type = val
        },
        validateForm(formName) {
            let validate = false
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    validate = true
                    return true
                } else {
                    validate = false
                    return false;
                }
            });
            return validate
        },
    },
    async mounted() {
        this.detailLoading = true
        let id = this.$route.params.id
        this.form.id = id
        console.log(id)
        await this.getStoreDetail({ id: id }).then(res => {
            console.log(res)
            if (res.ret === 0) {
                this.form.credit = res.data.credit
                this.form.id = res.data.id
                this.form.name = res.data.name
                this.form.type = res.data.type
                this.form.status = res.data.status
                this.form.pic = res.data.pic
                this.form.price = res.data.price
                this.form.content = res.data.content
                this.form.maxCount = res.data.max_count
                this.form.isAddress = res.data.is_address
                this.form.payType = res.data.pay_type
                this.form.pricePay = res.data.price_pay
                this.form.convertPrice = res.data.convert_price

            }
        })

        this.detailLoading = false
    }
}
</script>

<style>
</style>
